<template>
  <div v-loading="isLoading">
    <div
      class="mt-2 mr-1 mb-2 d-flex align-content-center justify-content-center align-items-center"
    >
      <div class="flex-fill pl-3">
        <h5>
          <i class="far fa-comment text-primary"></i>
          Total {{elements.pagingItem.outRowsNumber}} {{elements.pagingItem.outRowsNumber > 1 ? "comments" : "comment"}}
        </h5>
      </div>
      <div class="mr-2 pr-3 d-flex align-content-center justify-content-center align-items-center">
        <button type="button" class="btn btn-sm btn-primary" @click="refreshClick">
          <i class="fas fa-sync-alt"></i> Refresh
        </button>
      </div>
    </div>
    <!-- The time line -->
    <div class="timeline mb-1">
      <!-- timeline time label -->
      <!--<div class="time-label">
                <span class="bg-primary pl-3 pr-3">
                    
                </span>
      </div>-->
      <!-- /.timeline-label -->
      <!-- timeline item -->
      <div class="d-flex">
        <div class="image ml-3">
          <img
            :src="`/img/avatars/${userInfo.username}.jpg`"
            onerror="this.src='/img/avatar5.png'"
            alt="User Image"
            style="width: 35px;"
            class="img-circle elevation-2"
          />
        </div>
        <div class="timeline-item ml-2 w-100">
          <h3 class="timeline-header">
            <a href="javascript:void(0);">
              <i class="fas fa-plus-circle"></i> Post a new comment
            </a>
          </h3>
          <div class="timeline-body">
            <div class="form-group mb-2">
              <textarea
                type="text"
                class="form-control"
                :class="{ 'state-error' : contentInValid }"
                style="resize: vertical;line-height: 1.5rem; font-size: 1rem;"
                placeholder="Enter the text of the note.."
                rows="2"
                v-model="postNewCommentModel"
              ></textarea>
            </div>
            <div class="text-right">
              <button
                type="button"
                class="btn btn-warning btn-sm"
                :disabled="postNewCommentModel.length === 0"
                @click="postNewComment"
              >
                <i class="far fa-paper-plane"></i> &nbsp;&nbsp;&nbsp;Post comment
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- END timeline item -->
      <!-- timeline item -->
      <div class="d-flex" :key="element.noteId" v-for="element in elements.data">
        <div class="image ml-3">
          <img
            :src="`/img/avatars/${element.updatedBy}.jpg`"
            :alt="element.updatedBy"
            onerror="this.src='/img/avatar5.png'"
            style="width: 35px;"
            class="img-circle elevation-2"
          />
        </div>
        <div
          class="timeline-item ml-2 w-100"
          v-bind:class="{ owned : (element.updatedBy === userInfo.username) }"
        >
          <span class="time">
            <i class="fas fa-clock"></i>
            {{element.updatedAt | pretyDateUtc}}
          </span>
          <h3 class="timeline-header">
            <a href="javascript:void(0);">{{element.updatedBy}}</a> write a note
          </h3>
          <div class="timeline-body">
            <div>
              <!-- <div v-if="!element.isEditMode"> -->
              <div v-html="element.noteContent.replace(/\n/g, '<br>\n')" v-linkified></div>
              <!--<div class="mt-1">
                                <a class="ml-0 btn btn-xs btn-primary" 
                                    v-if="element.updatedBy === userInfo.username"
                                    @click="editComment(element)"
                                    href="javascript:void(0);">Edit this comment</a>
                                <a class="ml-2 btn btn-xs btn-danger" 
                                    v-if="element.updatedBy === userInfo.username"
                                    @click="deleteNote(element)"
                                    href="javascript:void(0);">Delete this comment</a>
              </div>-->
            </div>
            <!-- <div v-else>
                            <div class="form-group mb-1">
                                <textarea type="text" class="form-control" :class="{ 'state-error' : contentInValid }"
                                            style="resize: vertical;line-height: 1.5rem; font-size: 1rem;"
                                            placeholder="Nhập nội dung ghi chú.." rows="2"
                                            v-model="postModifiedCommentModel.noteContentEdit"></textarea>
                            </div>
                            <div class="text-right">
                                <button type="button"
                                   @click="postModifiedComment"
                                   :disabled="postModifiedCommentModel.noteContentEdit.length === 0"
                                   class="btn btn-primary text-white btn-sm">Save change</button>
                                <button type="button"
                                   @click="cancelEdit"
                                   class="btn btn-default btn-sm ml-2">Cancel</button>
                            </div>
            </div>-->
          </div>
        </div>
      </div>
      <!-- END timeline item -->
    </div>

    <div
      class="mt-2 mr-1 d-flex align-content-center justify-content-center align-items-center"
      v-if="elements.data.length > 0"
    >
      <div class="flex-fill pl-3"></div>
      <div
        class="mr-2 pr-2 d-flex align-content-center justify-content-center align-items-center"
        v-if="elements.pagingItem.numberOfPage > 1"
      >
        <paginate
          v-model="elements.pagingItem.pageIndex"
          :page-count="elements.pagingItem.numberOfPage"
          :page-range="5"
          :click-handler="pageClickHandle"
          :prev-text="'Prev'"
          :next-text="'Next'"
          container-class="pagination pagination-sm"
          page-class="paginate_button page-item"
          prev-class="paginate_button page-item"
          next-class="paginate_button page-item"
          page-link-class="page-link"
          prev-link-class="page-link"
          next-link-class="page-link"
        ></paginate>
      </div>
    </div>
  </div>
</template>

<style scoped>
.timeline-item.owned {
  border: 2px solid #12acffd9 !important;
}
</style>


<script>
import commonAct from "@/commonActionHandle.js";
import baseComponent from "@/scripts/baseComponent";
import usersApi from "@/api/common/users";
import { debounce } from "vue-debounce";
import { mapState } from "vuex";
export default {
  extends: baseComponent,
  props: {
    detailItem: {
      type: Object,
      default: null,
    },
    module: {
      type: String,
      default: null,
    },
    settings: {
      type: Object,
      default() {
        return { itemIdCol: "id" };
      },
    },
  },
  watch: {
    detailItem: {
      deep: true,
      handler() {
        console.log("The list of colours has changed!");
        this.getSearchQueryResult(1);
      },
    },
  },
  data() {
    return {
      debounceFnc: null,
      elements: {
        querySearch: "",
        data: [],
        pagingItem: {
          pageIndex: 1,
          pageSize: 20,
          numberOfPage: 1,
          outRowsNumber: 0,
          orderBy: "created_at",
          directionSort: "desc",
        },
      },
      elementDetail: null,
      postNewCommentModel: "",
      postModifiedCommentModel: {},
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.auth.user,
    }),
    defaultPagingItem() {
      return {
        pageIndex: 1,
        pageSize: 20,
        numberOfPage: 1,
        outRowsNumber: 0,
        orderBy: "created_at",
        directionSort: "desc",
      };
    },
    requestParam() {
      return {
        querySearch: this.elements.querySearch,
        pageSize: this.elements.pagingItem.pageSize,
      };
    },
    contentInValid() {
      let format = /[\\`'"]/;
      //console.log(format.test(this.detailUserNote.noteContentEdit));
      //return format.test(this.detailUserNote.noteContentEdit);
      return false; //format.test(this.detailUserNote.noteContentEdit);
    },
  },
  created() {
    this.getSearchQueryResult(1);
    this.debounceFnc = debounce(() => {
      this.getSearchQueryResult(1);
    }, 1000);
  },
  methods: {
    getNoteList(pageNumber) {
      this.showLoading();
      usersApi
        .getNoteElementsList(
          this.module,
          this.detailItem[this.settings.itemIdCol],
          pageNumber,
          this.requestParam
        )
        .then((response) => {
          if (response.data.result === 0) {
            // response.data.data.data.forEach((item) => {
            //   item.isEditMode = false;
            // });
            this.$set(this.elements, "data", response.data.data.data);
            this.$set(
              this.elements,
              "pagingItem",
              response.data.data.pagingItem
            );
            this.hideLoading();
          } else {
            this.$set(this.elements, "data", []);
            commonAct.showError(response.data.message);
            this.hideLoading();
          }
        })
        .catch((error) => {
          this.$set(this.elements, "data", []);
          console.error(error);
          commonAct.showError(error);
          this.hideLoading();
        });
    },
    getSearchQueryResult(pageNumber) {
      this.getNoteList(pageNumber);
    },
    pageClickHandle(pageNumber) {
      this.getSearchQueryResult(pageNumber);
    },
    refreshClick() {
      this.elements.querySearch = "";
      this.getSearchQueryResult(1);
    },
    onSearchInput(isDebounce) {
      if (isDebounce) {
        if (this.debounceFnc) this.debounceFnc();
      } else {
        this.getSearchQueryResult(1);
      }
    },
    postNewComment() {
      this.showLoading();
      usersApi
        .saveDetailUserNote({
          noteId: null,
          userId: this.detailItem[this.settings.itemIdCol].toString(),
          itemId: this.detailItem[this.settings.itemIdCol].toString(),
          module: this.module,
          noteContentEdit: this.postNewCommentModel,
          deviceInformation: JSON.stringify(this.getInformationClient()),
        })
        .then((response) => {
          if (response.data && response.data.result === 0) {
            let userNoteCreated = response.data.data;
            if (this.elements.pagingItem.pageIndex === 1) {
              this.elements.data.unshift(userNoteCreated);
              if (this.elements.data.length > this.elements.pagingItem.pageSize)
                this.elements.data.splice(-1, 1);
            }
            this.elements.pagingItem.outRowsNumber += 1;
            this.$emit("newestCommentUpdated", this.postNewCommentModel);
            this.$set(this.detailItem, "noteContent", this.postNewCommentModel);
            this.showSuccessToast("User note information has been saved");
            this.postNewCommentModel = "";
          } else {
            commonAct.showError(response.data.message);
          }
          this.hideLoading();
        })
        .catch((error) => {
          console.error(error);
          commonAct.showError(error);
          this.hideLoading();
        });
    },
    editComment(commentItem) {
      console.log("commentItem", commentItem);
      this.postModifiedCommentModel = commentItem;
      this.$set(
        this.postModifiedCommentModel,
        "noteContentEdit",
        commentItem.noteContent
      );
      this.$set(commentItem, "isEditMode", true);
    },
    postModifiedComment() {
      this.showLoading();
      usersApi
        .saveDetailUserNote({
          noteId: this.postModifiedCommentModel.noteId,
          userId: this.detailItem[this.settings.itemIdCol].toString(),
          itemId: this.detailItem[this.settings.itemIdCol].toString(),
          module: this.module,
          noteContentEdit: this.postModifiedCommentModel.noteContentEdit,
        })
        .then((response) => {
          if (response.data && response.data.result === 0) {
            let userNoteCreated = response.data.data;
            let foundedItemIndex = this.elements.data.findIndex((x) => {
              return x.noteId === userNoteCreated.noteId;
            });
            if (foundedItemIndex > -1) {
              this.elements.data[foundedItemIndex].isEditMode = false;
              this.$set(this.elements.data, foundedItemIndex, userNoteCreated);
              if (foundedItemIndex === 0) {
                this.$emit(
                  "newestCommentUpdated",
                  this.postModifiedCommentModel.noteContentEdit
                );
                this.$set(
                  this.detailItem,
                  "noteContent",
                  this.postModifiedCommentModel.noteContentEdit
                );
              }
            }
            this.showSuccessToast("User note information has been saved");
            this.postModifiedCommentModel = {};
          } else {
            commonAct.showError(response.data.message);
          }
          this.hideLoading();
        })
        .catch((error) => {
          console.error(error);
          commonAct.showError(error);
          this.hideLoading();
        });
    },
    cancelEdit() {
      let foundedItemIndex = this.elements.data.findIndex((x) => {
        return x.noteId === this.postModifiedCommentModel.noteId;
      });
      if (foundedItemIndex > -1) {
        this.elements.data[foundedItemIndex].isEditMode = false;
        this.postModifiedCommentModel.noteContentEdit = "";
      }
      this.postModifiedCommentModel = {};
    },
    deleteNote(itemDelete) {
      commonAct.showConfirm("Delete comment. This cannot be undone?", () => {
        this.showLoading();
        usersApi
          .deleteNoteById(itemDelete.noteId)
          .then((response) => {
            if (response.data.result === 0) {
              let foundedItemIndex = this.elements.data.findIndex((x) => {
                return x.noteId === itemDelete.noteId;
              });
              if (foundedItemIndex > -1) {
                this.elements.data.splice(foundedItemIndex, 1);
                this.getSearchQueryResult(this.elements.pagingItem.pageIndex);
                if (this.elements.data.length > 0)
                  this.$set(
                    this.detailItem,
                    "noteContent",
                    this.elements.data[0].noteContent
                  );
                else this.$set(this.detailItem, "noteContent", "");
              }
            } else {
              commonAct.showError(response.data.message);
            }
            this.hideLoading();
          })
          .catch((error) => {
            console.error(error);
            commonAct.showError(error);
            this.hideLoading();
          });
      });
    },
  },
};
</script>